class TokenService {
    getLocalRefreshToken() {
      const user = JSON.parse(localStorage.getItem("partner_token"));
      return user;
    }
  
    getLocalAccessToken() {
      const user = JSON.parse(localStorage.getItem("partner_token"));
      return user;
    }
  
    updateLocalAccessToken(token) {
      localStorage.setItem("partner_token", token);
    }
  
    getUser() {
      return JSON.parse(localStorage.getItem("partner_token"));
    }
  
    setUser(user) {
      localStorage.setItem("partner_token", JSON.stringify(user));
    }
  
    removeUser() {
      localStorage.removeItem("partner_token");
    }
  }
  
  const service = new TokenService();
  export default service;
  