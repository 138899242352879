import React, { useState } from 'react';
import './flags.css';
const Flags = ({updateFlagData}) => {
  // State to store the current text and image URL
  const [buttonText, setButtonText] = useState('FSA');
  const [imageUrl, setImageUrl] = useState('https://account.godocm.com/images/saint_2.png');

  // Function to handle button click
  const toggleTextAndImage = () => {
    // Toggle button text between "FSC" and "FSA"
    setButtonText(buttonText === 'FSC' ? 'FSA' : 'FSC');
    // Toggle image URL
    setImageUrl(imageUrl === 'https://account.godocm.com/images/saint_2.png' ? 'https://account.godocm.com/images/mauritius_2.png' : 'https://account.godocm.com/images/saint_2.png');
    updateFlagData(buttonText === 'FSC' ? 'FSA' : 'FSC');
  };
  return (
    <div className="changeDropdown" style={{display: 'none'}}>
      <div className="dropdown">
        <button onClick={toggleTextAndImage} className="dropbtn">
          <img
            src={imageUrl}
            style={{ width: '30px', marginRight: '5px' }}
            alt="Flag"
          />
          {buttonText}
        </button>
        {/*<button onClick={myFunction} className="dropbtn">
          <img
            src="https://account.godocm.com/images/mauritius_2.png"
            style={{ width: '30px', marginRight: '5px' }}
            alt="Flag"
          />
          FSC
          <i className="arrows downs"></i>
        </button>
        <div id="myDropdown1" className="dropdown-content">
          <a href="https://account.godocm.com/mu/registration.html" className="dynamic-link">
            <img
              src="https://account.godocm.com/images/mauritius_2.png"
              style={{ width: '30px', marginRight: '5px' }}
              alt="Flag"
            />
            FSC
          </a>
          <a href="https://account.godocm.com/registration.html" className="dynamic-link">
            <img
              src="https://account.godocm.com/images/saint_2.png"
              style={{ width: '30px', marginRight: '5px' }}
              alt="Flag"
            />
            FSA
          </a>
  </div>*/}
      </div>
    </div>
  );
};

export default Flags;
